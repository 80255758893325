import React from "react"
import Link from "gatsby-link"

import Layout from "../components/layout"
import SEO from "../components/seo"
import classes from "./404.module.css"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section className={classes.notFound}>
      <h1>NOT FOUND</h1>
      <br/>
      <p>You've hit a route that doesn&#39;t exist...</p>
      <Link to="/">Go Home</Link>
    </section>

  </Layout>
)

export default NotFoundPage
